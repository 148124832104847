export default [
  {
    title: "Property Investing All-in-One For Dummies",
    yearRead: 2025,
    author: "Melanie Bien",
    nonfiction: true,
    imgSrc: "https://i.ebayimg.com/images/g/g1gAAOSwjRFbZCqH/s-l500.webp",
  },
  {
    title: "Flow Architectures",
    yearRead: 2025,
    author: "James Urquhart",
    nonfiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/91hDhctbAIL._SL1500_.jpg",
  },
  {
    title: "1984",
    yearRead: 2025,
    author: "George Orwell",
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/612ADI+BVlL._SL1500_.jpg",
  },
  {
    title: "Learning Systems Thinking",
    yearRead: 2025,
    author: "Diana Montalion",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/812xw53x2iL._SL1500_.jpg",
  },
  {
    title: "Magician",
    yearRead: 2025,
    author: "Raymond Feist",
    nonfiction: false,
    fiction: true,
    reread: true,
    imgSrc: "https://m.media-amazon.com/images/I/71rGCrLswvL._SL1500_.jpg",
  },
  {
    title: "Anna Karenina",
    yearRead: 2025,
    author: "Leo Tolstoy",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/81x7jrxd2TL._SL1500_.jpg",
  },
  {
    title: "Sooner Safer Happier",
    yearRead: 2025,
    author: "Jonathan Smart",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/81Xqulj5NeL._SL1500_.jpg",
  },
  {
    title: "Meditations",
    yearRead: 2024,
    author: "Marcus Aurelius",
    nonfiction: true,
    fiction: false,
    reread: true,
    imgSrc: "https://m.media-amazon.com/images/I/81DFDGzHZqL._SL1500_.jpg",
  },
  {
    title: "Learning Domain Driven Design",
    yearRead: 2024,
    author: "Vlad Khononov",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/819gVKYN7HL._SL1500_.jpg",
  },
  {
    title: "Team Topologies",
    yearRead: 2024,
    author: "Matthew Skelton",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/71rps7F0FuL._SL1500_.jpg",
  },
  {
    title: "Wuthering Heights",
    yearRead: 2024,
    author: "Emily Bronte",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/81T34Sem-tL._SL1500_.jpg",
  },
  {
    title: "The Morell Compass",
    yearRead: 2024,
    author: "Connor Melvin",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/719sviuLJXL._SL1500_.jpg",
  },
  {
    title: "The Go Programming Language",
    yearRead: 2024,
    author: "Alan A. A. Donovan, Brian W. Kernighan",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/61GNpAHFttL._SL1281_.jpg",
  },
  {
    title: "Agile Technical Practices Distilled",
    yearRead: 2024,
    author: "Pedro Santos, Marco Consolaro, Alessandro Di Gioia",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/61jBOEkmpXL._SL1360_.jpg",
  },
  {
    title: "Letters from a Stoic",
    yearRead: 2024,
    author: "Seneca",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/61EVmWoiuaL._SL1200_.jpg",
  },

  {
    title: "Sunbringer",
    yearRead: 2025,
    author: "Hannah Kaner",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/91GplDaThSL._SL1500_.jpg",
  },
  {
    title: "Politics on the Edge",
    yearRead: 2024,
    author: "Rory Stewart",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/81w+hBKx-VL._SL1500_.jpg",
  },
  {
    title: "Pride & Prejudice",
    yearRead: 2024,
    author: "Jane Austen",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/81NLDvyAHrL._SL1500_.jpg",
  },
  {
    title: "Meditations",
    yearRead: 2024,
    author: "Marcus Aurelius",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/81DFDGzHZqL._SL1500_.jpg",
  },
  {
    title: "The Obstacle is the Way",
    yearRead: 2024,
    author: "Ryan Holiday",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/81aRj4UCGSL._SL1500_.jpg",
  },
  {
    title: "Godkiller",
    yearRead: 2024,
    author: "Hannah Kaner",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/71x2zvlsxjL._SL1181_.jpg",
  },
  {
    title: "The Lord of the Rings - The Return of the King Audiobook",
    yearRead: 2024,
    author: "JRR Tolkien / Andy Serkis",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/61JTUA83gwL._SL500_.jpg",
  },
  {
    title: "Toll the Hounds",
    yearRead: 2024,
    author: "Steven Erikson",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/61mjf1-iRgL._SL1052_.jpg",
  },
  {
    title: "Reaper's Gale",
    yearRead: 2024,
    author: "Steven Erikson",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/61TvLv6xlLL._SL1200_.jpg",
  },
  {
    title: "The Bonehunters",
    yearRead: 2024,
    author: "Steven Erikson",
    nonfiction: false,
    fiction: true,
    imgSrc:
      "https://upload.wikimedia.org/wikipedia/en/c/cf/The_Bonehunters_1st_ed.jpg",
  },
  {
    title: "Continuous Delivery",
    yearRead: 2024,
    author: "Jez Humble, Dave Farley",
    nonfiction: true,
    fiction: false,
    imgSrc: "https://m.media-amazon.com/images/I/71sYKaNItcL._SL1500_.jpg",
  },
  {
    title: "Release it!",
    yearRead: 2024,
    author: "Michael T. Nygard",
    nonfiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/51YLCdLeopS._SL1000_.jpg",
  },
  {
    title: "The Lord of the Rings - The Two Towers Audiobook",
    yearRead: 2024,
    author: "JRR Tolkien / Andy Serkis",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/61YMnN7VzmL._SL500_.jpg",
  },
  {
    title: "The Lord of the Rings - Fellowship of the Ring Audiobook",
    yearRead: 2023,
    author: "JRR Tolkien / Andy Serkis",
    nonfiction: false,
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/61irmlqdpWL._SL500_.jpg",
  },
  {
    title: "Domain Driven Design",
    yearRead: 2023,
    author: "Eric Evans",
    nonfiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/51OWGtzQLLL._SX260_.jpg",
  },
  {
    title: "Midnight Tides",
    yearRead: 2023,
    author: "Steven Erikson",
    fiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/P/0553813145.01._SCLZZZZZZZ_SX500_.jpg",
  },
  {
    title: "House of Chains",
    yearRead: 2023,
    author: "Steven Erikson",
    fiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/P/0553813137.01._SCLZZZZZZZ_SX500_.jpg",
  },
  {
    title: "Memories of Ice",
    yearRead: 2023,
    author: "Steven Erikson",
    fiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/P/0553813129.01._SCLZZZZZZZ_SX500_.jpg",
  },
  {
    title: "Deadhouse Gates",
    yearRead: 2022,
    author: "Steven Erikson",
    fiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/P/0553813110.01._SCLZZZZZZZ_SX500_.jpg",
  },
  {
    title: "Bad Feminist",
    yearRead: 2023,
    author: "Roxane Gay",
    nonfiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/P/B00M0T03EC.01._SCLZZZZZZZ_SX500_.jpg",
  },
  {
    title: "The Making of a Manager",
    yearRead: 2023,
    author: "Julie Zhuo",
    nonfiction: true,
    imgSrc:
      "http://books.google.com/books/content?id=SPNLDwAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api",
  },
  {
    title: "The Motorcycle Diaries",
    yearRead: 2023,
    author: "Che Guvera",
    nonfiction: true,
    imgSrc:
      "http://books.google.com/books/content?id=1zYGEAAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api",
  },
  {
    title: "Atomic Habits",
    yearRead: 2023,
    author: "James Clear",
    nonfiction: true,
    imgSrc:
      "http://books.google.com/books/content?id=fFCjDQAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api",
  },
  {
    title: "Radical Candor",
    yearRead: 2023,
    author: "Kim Scott",
    nonfiction: true,
    imgSrc:
      "http://books.google.com/books/content?id=zbYDDQAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api",
  },
  {
    title: "On Writing",
    yearRead: 2023,
    author: "Stephen King",
    nonfiction: true,
    imgSrc:
      "http://books.google.com/books/content?id=d999Z2KbZJYC&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api",
  },
  {
    title: "INSPIRED",
    yearRead: 2022,
    author: "Marty Cagan",
    nonfiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/41lhsQn6ZdL._SY346_.jpg",
  },
  {
    title: "Lean from the Trenches",
    yearRead: 2022,
    author: "Henrik Kniberg",
    nonfiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/P/1934356859.01._SCLZZZZZZZ_SX500_.jpg",
  },
  {
    title: "User Story Mapping",
    yearRead: 2022,
    author: "Jeff Patton",
    nonfiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/51OBhcSAfAL._SX331_BO1,204,203,200_.jpg",
  },
  {
    title: "The First Kingdom",
    yearRead: 2022,
    author: "Max Adams",
    nonfiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/I/914P2GRooOL._AC_UY436_FMwebp_QL65_.jpg",
  },
  {
    title: "Wealth of Nations",
    yearRead: 2022,
    author: "Adam Smith",
    nonfiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/51XJWydjooL._SX317_BO1,204,203,200_.jpg",
  },
  {
    title: "A History of the World in Twelve Maps",
    yearRead: 2022,
    author: "Jerry Brotton",
    nonfiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/51eL65Bbo+L._SY344_BO1,204,203,200_.jpg",
  },
  {
    title: "Accelerate",
    yearRead: 2022,
    author: "Nicole Forsgren, Jez Humble, Nicole Forsgren, Gene Kim",
    nonfiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/51CjXuVck+L.jpg",
  },
  {
    title: "Dune",
    yearRead: 2022,
    author: "Frank Herbert",
    fiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/41rxoi1jMQL._SX331_BO1,204,203,200_.jpg",
  },
  {
    title: "On Fire",
    yearRead: 2022,
    author: "Ben Stokes",
    nonfiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/41Cc43F6GYL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "A Poem for Every Feeling",
    yearRead: 2022,
    author: "An Anthology by Ella Risbridger",
    fiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/61FY5knjdML._SX388_BO1,204,203,200_.jpg",
  },
  {
    title: "The Lord of the Rings",
    yearRead: 2022,
    author: "JRR Tolkien",
    reread: true,
    fiction: true,
    imgSrc:
      "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1566425108l/33.jpg",
  },
  {
    title: "The Pragmatic Programmer",
    yearRead: 2022,
    author: "David Thomas, Andrew Hunt, et al.",
    nonfiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/I/91WFb-PpoNL._AC_UY436_FMwebp_QL65_.jpg",
  },
  {
    title: "Effective Typescript",
    yearRead: 2022,
    author: "Dan Vanderkam",
    nonfiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/41nCjLf7YiL._SX379_BO1,204,203,200_.jpg",
  },

  {
    title: "Caliban's War",
    yearRead: 2021,
    author: "James S. A. Corey",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51CW2rF7tyL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Functional Programming in Scala",
    yearRead: 2020,
    author: "Paul Chiusano",
    nonfiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/I/61sXJfXWscL._AC_UY436_FMwebp_QL65_.jpg",
  },
  {
    title: "Eye of the World",
    yearRead: 2018,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/51nDTUXFvaL._SX316_BO1,204,203,200_.jpg",
  },
  {
    title: "The Great Hunt",
    yearRead: 2018,
    author: "Robert Jordan",
    fiction: true,
    imgSrc: "https://images-na.ssl-images-amazon.com/images/I/7198HZnoMWL.jpg",
  },
  {
    title: "The Dragon Reborn",
    yearRead: 2018,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/51BFTkIP2VL._SX316_BO1,204,203,200_.jpg",
  },
  {
    title: "The Shadow Rising",
    yearRead: 2018,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/51Ko-wu8nwL._SX317_BO1,204,203,200_.jpg",
  },
  {
    title: "Code Complete",
    yearRead: 2018,
    author: "Steve McConnell",
    nonfiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/41JOmGowq-L._SX258_BO1,204,203,200_.jpg",
  },
  {
    title: "Head First Design Patterns",
    yearRead: 2018,
    author: "Eric Freeman",
    nonfiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/61APhXCksuL._SX218_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Sapiens - Audiobook",
    yearRead: 2018,
    author: "Yuval Noah Harari",
    nonfiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/41yu2qXhXXL._SX324_BO1,204,203,200_.jpg",
  },
  {
    title: "Harry Potter and the Philosopher's Stone - Audiobook",
    yearRead: 2018,
    author: "J.K. Rowling",
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/51UoqRAxwEL.jpg",
  },
  {
    title: "Harry Potter and the Chamber of Secrets - Audiobook",
    yearRead: 2018,
    author: "J.K. Rowling",
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/51TA3VfN8RL._SY346_.jpg",
  },
  {
    title: "Harry Potter and the Prisoner of Azkaban - Audiobook",
    yearRead: 2018,
    author: "J.K. Rowling",
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/51Dfqo6jR5L.jpg",
  },
  {
    title: "Harry Potter and the Goblet of the Fire - Audiobook",
    yearRead: 2018,
    author: "J.K. Rowling",
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/51Vjb2qJwzL.jpg",
  },
  {
    title: "Harry Potter and the Order of the Phoenix - Audiobook",
    yearRead: 2018,
    author: "J.K. Rowling",
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/51-SI2+aQ2L.jpg",
  },
  {
    title: "Harry Potter and the Half-Blood Prince - Audiobook",
    yearRead: 2019,
    author: "J.K. Rowling",
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/51myHyjJsyL._SY346_.jpg",
  },
  {
    title: "Harry Potter and the Deathly Hallows - Audiobook",
    yearRead: 2019,
    author: "J.K. Rowling",
    fiction: true,
    imgSrc: "https://m.media-amazon.com/images/I/511DhzIj4cL._SY346_.jpg",
  },
  {
    title: "Histories of Nations",
    yearRead: 2019,
    author: "Peter Furtado",
    nonfiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/518c55YRyYL._SX326_BO1,204,203,200_.jpg",
  },
  {
    title: "The Lord of the Rings",
    yearRead: 2019,
    author: "JRR Tolkien",
    fiction: true,
    reread: true,
    imgSrc:
      "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1566425108l/33.jpg",
  },
  {
    title: "The Fires of Heaven",
    yearRead: 2019,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51ojeC9x22L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Lord of Chaos",
    yearRead: 2019,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51YjqYj9waL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "A Crown of Swords",
    yearRead: 2019,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51wYoFtmXSL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Hobbit",
    yearRead: 2019,
    author: "JRR Tolkien",
    fiction: true,
    reread: true,
    imgSrc:
      "https://cdn.waterstones.com/bookjackets/large/9780/2611/9780261103283.jpg",
  },
  {
    title: "A Wizard of Sea",
    yearRead: 2019,
    author: "Ursula K. Le Guin",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51iQfxB5rHL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Leviathan Wakes",
    yearRead: 2020,
    author: "James S. A. Corey",
    fiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/511RTVPcqaL._SX317_BO1,204,203,200_.jpg",
  },
  {
    title: "Down Under",
    yearRead: 2020,
    author: "Bill Bryson",
    nonfiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51PSH4K4amL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Natives",
    yearRead: 2020,
    author: "Akala",
    nonfiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/51ya6G0fWUL._SX324_BO1,204,203,200_.jpg",
  },
  {
    title: "Fire and Blood",
    yearRead: 2020,
    author: "George RR Martin",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51RVf7KG8fL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Tombs of Atuan",
    yearRead: 2020,
    author: "Ursula K. Le Guin",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51tLvYdbRcL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Path of Daggers",
    yearRead: 2020,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51mJySfilIL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Winter's Heart",
    yearRead: 2020,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/61tjO5Bz-9L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Crossroads of Twilight",
    yearRead: 2020,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51wH0fnkZqL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Knife of Dreams",
    yearRead: 2020,
    author: "Robert Jordan",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/61fCreMGJwL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Gathering Storm",
    yearRead: 2021,
    author: "Robert Jordan & Brandon Sanderson",
    fiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/51QSYtr6NtL._SX316_BO1,204,203,200_.jpg",
  },
  {
    title: "Towers of Midnight",
    yearRead: 2021,
    author: "Robert Jordan & Brandon Sanderson",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51qQPiz8q0L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "A Memory of Light",
    yearRead: 2021,
    author: "Robert Jordan & Brandon Sanderson",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/51re3ONzSBL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Distributed Systems in Node.js",
    yearRead: 2021,
    author: "Thomas Hunter",
    nonfiction: true,
    imgSrc:
      "https://m.media-amazon.com/images/I/81S87xwKCvL._AC_UY436_FMwebp_QL65_.jpg",
  },
  {
    title: "The Design of Everyday Things",
    yearRead: 2021,
    author: "Donald Norman",
    nonfiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/41bWcNdTGmL._SX330_BO1,204,203,200_.jpg",
  },
  {
    title: "Gardens of the Moon",
    yearRead: 2021,
    author: "Steven Erikson",
    fiction: true,
    imgSrc:
      "https://images-na.ssl-images-amazon.com/images/I/41iY-pZ9PAL._SX298_BO1,204,203,200_.jpg",
  },
  {
    title: "The Farthest Shore",
    yearRead: 2021,
    author: "Ursula K. Le Guin",
    fiction: true,
    imgSrc:
      "https://images-eu.ssl-images-amazon.com/images/I/41TUQacykuL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
];
